
import { defineComponent, reactive, toRefs, ref } from 'vue'
import pagaTable from '@/components/pagination-table/index'
import formSearch from '@/components/page-search/form'
import popupWindow from './popupNew.vue'
import {
  GoodsController_page,
  GoodsController_del
} from '@/api/commodityManagement/allGoods'
import { message } from 'ant-design-vue'
const purchaseColumns = [
  {
    title: '商品id',
    dataIndex: 'goodsId'
  },
  {
    title: '商品名称',
    dataIndex: 'name'
  },
  {
    title: '商品图片',
    dataIndex: 'attachFile'
  },
  {
    title: '商品状态',
    dataIndex: 'state'
  },
  {
    title: '商品类型',
    dataIndex: 'category'
  },
  {
    title: '是否包邮',
    dataIndex: 'mail'
  },
  {
    title: '起售价',
    dataIndex: 'price'
  },
  {
    title: '库存',
    dataIndex: 'stock'
  },
  {
    title: '销量',
    dataIndex: 'salesVolume'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    slots: { customRender: 'operation' }
  }
]
export default defineComponent({
  name: 'questionType',
  components: {
    pagaTable,
    popupWindow,
    formSearch
  },
  setup(props: any) {
    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 16 },
        formItemProps: {
          wrapperCol: {
            span: 24
          }
        }
      }
    })
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys
      console.log(data.selectedRowKeys)
    }
    const table = ref()
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        const params_api = {
          currentPage: params.pageIndex,
          pageSize: params.pageSize
        }
        GoodsController_page(params_api).then((res) => {
          reslove(res)
        })
      })
    }
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      // console.log(pagination, filter, sorter, currentDataSource);
    }
    const handleClick = (text: string, row: any) => {
      Title.value = '编辑'
      popupWindow.value.showModal(row)
    }
    const onClose = () => {
      specificationVisible.value = false
    }
    const formSearch = ref()
    const popupWindow = ref()
    const specificationRef = ref()
    const popupRandom = ref()
    const specificationTile = ref()
    const dataSource = ref()
    const specificationVisible = ref<boolean>(false)
    const Title = ref()
    const updatedTime = ref([])
    const handleAdd = () => {
      Title.value = '新增商品'
      popupWindow.value.showModal()
    }
    const handleSearch = (formState: any) => {
      table.value.refresh()
    }
    const refresh = () => {
      table.value.refresh()
      data.selectedRowKeys = []
    }

    const visible = ref<boolean>(false)
    const questions = ref([] as any[])
    const del = (val: any) => {
      GoodsController_del({ id: val.goodsSpecId }).then((res) => {
        table.value.refresh()
        message.success('操作成功')
      })
    }
    const roleId = ref()
    return {
      onClose,
      dataSource,
      specificationTile,
      specificationVisible,
      questions,
      visible,
      del,
      refresh,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      specificationRef,
      popupRandom,
      Title,
      handleAdd,
      handleSearch,
      updatedTime
      // success
    }
  }
})
